import { css } from 'styled-components'

const colors = css`
  --color-primary-rgb: 241, 177, 60;
  --color-primary: rgb(var(--color-primary-rgb));
  --color-primary: rgb(241, 177, 60);
  --color-primary-a1: rgb(241, 177, 60, 0.1);
  --color-primary-a2: rgb(241, 177, 60, 0.2);
  --color-primary-a5: rgb(241, 177, 60, 0.5);
  --color-primary-244: rgb(244, 178, 41);

  --color-scondary: rgba(72, 158, 177, 0.61);

  --color-red-193: rgb(193, 79, 78);
  --color-red-193-a100: rgba(193, 70, 78, 1);
  --color-red-193-a17: rgba(193, 70, 78, 0.17);
  --color-red-202: rgb(202, 52, 31);
  --color-red-210: rgb(210, 103, 99);
  --color-red-255: rgb(255, 0, 0);
  --color-green-50: rgb(50, 215, 75);
  --color-green-69: rgb(69, 201, 171);
  --color-green-69-a17: rgba(69, 201, 171, 0.17);
  --color-green-76: rgb(76, 175, 80);
  --color-green-82: rgba(82, 122, 104, 1);
  --color-normal-147: rgba(147, 147, 147, 1);
  --color-normal-106-a2: rgba(106, 106, 106, 0.2);

  --color-kakao: rgb(254, 218, 49);
  --color-facebook: rgb(24, 119, 242);

  --color-blue-97: rgba(97, 177, 246, 1);
  --color-blue-97-a20: rgba(97, 177, 246, 0.2);

  --color-black: rgb(0, 0, 0);
  --color-black-40: rgb(40, 40, 40);
  --color-black-a6: rgba(0, 0, 0, 0.6);
  --color-black-a75: rgba(0, 0, 0, 0.75);
  --color-gray-14: rgb(14, 18, 29);
  --color-gray-19-a75: rgba(19, 23, 34, 0.75);
  --color-gray-19: rgb(19, 23, 34); /* main bg */
  --color-gray-23: rgb(23, 25, 30);
  --color-gray-24: rgba(24, 28, 41, 1);
  --color-gray-27-40: rgb(27, 31, 40);
  --color-gray-27: rgb(27, 31, 44);
  --color-gray-27-a30: rgba(27, 31, 44, 0.3);
  --color-gray-28: rgb(28, 32, 42);
  --color-gray-29: rgb(29, 34, 55);
  --color-gray-31: rgb(31, 39, 58);
  --color-gray-32: rgb(32, 38, 55);
  --color-gray-32-a50: rgba(32, 38, 55, 0.5);
  --color-gray-37: rgb(37, 44, 63);
  --color-gray-38: rgb(38, 41, 54);
  --color-gray-39-44-55: rgb(39, 44, 55);
  --color-gray-39: rgb(39, 47, 69);
  --color-gray-40: rgb(40, 46, 60);
  --color-gray-43: rgb(43, 43, 43);
  --color-gray-46: rgb(46, 52, 71);
  --color-gray-47: rgb(47, 54, 64);
  --color-gray-48: rgb(48, 51, 63);
  --color-gray-55: rgb(55, 57, 69);
  --color-gray-57: rgb(57, 72, 109);
  --color-gray-60: rgb(60, 60, 71);
  --color-gray-61: rgb(61, 64, 73);
  --color-gray-64: rgb(64, 67, 75);
  --color-gray-75-rgb: 75, 84, 106;
  --color-gray-75: rgb(75, 84, 106); // 27와 primary 모두 대비 되는 색상
  --color-gray-80: rgb(80, 80, 80);

  --color-gray-100: rgb(100, 102, 110);
  --color-gray-102: rgb(102, 102, 102);
  --color-gray-108: rgba(108, 115, 122, 1);
  --color-gray-114: rgb(114, 125, 134);
  --color-gray-116: rgba(116, 121, 137, 1);
  --color-gray-117: rgba(117, 117, 117, 1);
  --color-gray-128: rgb(128, 128, 128);
  --color-gray-138: rgb(138, 138, 138);
  --color-gray-151-a1: rgba(151, 151, 151, 0.1);
  --color-gray-154: rgb(154, 165, 174);
  --color-gray-158: rgb(158, 158, 158);
  --color-gray-164: rgb(164, 164, 164);
  --color-gray-180: rgb(180, 180, 180); // white black 배경에서 모두 시안성 있는 컬러
  --color-gray-188: rgb(188, 182, 170);
  --color-gray-186: rgba(186, 186, 186, 0.1);
  --color-gray-189: rgba(189, 189, 189, 1);
  --color-gray-196-a2: rgba(196, 196, 196, 0.2);
  --color-gray-196: rgb(196, 196, 196);
  --color-gray-197: rgb(197, 197, 197);
  --color-gray-207: rgb(207, 205, 218);
  --color-gray-209: rgb(209, 209, 209);
  --color-gray-223: rgb(223, 223, 239);
  --color-gray-254-a2: rgba(254, 254, 254, 0.2);
  --color-gray-255: rgba(255, 255, 255, 0.1);
  --color-white-250: rgba(250, 250, 250);
  --color-white-254: rgba(254, 254, 254, 1);
  --color-white: rgb(255, 255, 255);

  /** 상상인 테마별 컬러 **/
  body[data-theme='light'] {
    // Netural
    --color-sangsangin-white: #ffffff;
    --color-sangsangin-black: #000000;

    // Primary
    --color-sangsangin-primary-normal: #00b0ad;
    --color-sangsangin-primary-light: #32e5d9;
    --color-sangsangin-primary-dark: #00b0ad;

    // Secondary
    --color-sangsangin-secondary-navy: #273646;
    --color-sangsangin-secondary-gold: #c5aa7e;

    // ETC
    --color-sangsangin-etc-red: #ef2c26;
    --color-sangsangin-etc-blud: #007aff;
    --color-sangsangin-etc-filter-line-default: #e0e0e0;

    // Text
    --color-sangsangin-text-default: #222222;
    --color-sangsangin-text-sub: #424242;
    --color-sangsangin-text-info: #777777;
    --color-sangsangin-text-info-02: #9e9e9e;
    --color-sangsangin-text-disabled: #bfbfbf;

    // Icon
    --color-sangsangin-icon-default: #424242;
    --color-sangsangin-icon-sub: #777777;
    --color-sangsangin-icon-sub-02: #bfbfbf;
    --color-sangsangin-icon-disabled: #eeeeee;

    // Border
    --color-sangsangin-border-default: #bfbfbf;
    --color-sangsangin-border-strong: #424242;
    --color-sangsangin-border-disabled: #dddddd;

    // Input
    --color-sangsangin-bg-input-default: #fafafa;
    --color-sangsangin-bg-input-disabled: #eeeeee;
    --color-sangsangin-border-input-default: #f5f5f5;
    --color-sangsangin-border-input-disabled: #e2e2e2;

    // Background
    --color-sangsangin-bg-gray: #f4f5f6;
    --color-sangsangin-bg-card-ui: #f7f8f9;
    --color-sangsangin-bg-divider: #eeeeee;
    --color-sangsangin-bg-disabled: #eeeeee;
    --color-sangsangin-bg-white: #ffffff;
    --color-sangsangin-bg-red: #fff3f2;
    --color-sangsangin-bg-blue: #e0eefe;
    --color-sangsangin-bg-mint: #e3fcfa;
    --color-sangsangin-bg-dimmed: rgba(0, 0, 0, 0.4);
  }

  body[data-theme='dark'] {
    // Netural
    --color-sangsangin-white: #101923;
    --color-sangsangin-black: #ffffff;

    // Primary
    --color-sangsangin-primary-normal: #00bfb6;
    --color-sangsangin-primary-light: #32e5d9;
    --color-sangsangin-primary-dark: #00bfb6;

    // Secondary
    --color-sangsangin-secondary-navy: #ffffff;
    --color-sangsangin-secondary-gold: #c5aa7e;

    // ETC
    --color-sangsangin-etc-red: #ef4452;
    --color-sangsangin-etc-blud: #2172eb;
    --color-sangsangin-etc-filter-line-default: #495159;

    // Text
    --color-sangsangin-text-default: #eeeeee;
    --color-sangsangin-text-sub: #c1c3c6;
    --color-sangsangin-text-info: #8f9398;
    --color-sangsangin-text-info-02: #6b7077;
    --color-sangsangin-text-disabled: #4c525a;

    // Icon
    --color-sangsangin-icon-default: #c1c3c6;
    --color-sangsangin-icon-sub: #8f9398;
    --color-sangsangin-icon-sub-02: #4c525a;
    --color-sangsangin-icon-disabled: #232b35;

    // Border
    --color-sangsangin-border-default: #4c525a;
    --color-sangsangin-border-strong: #c1c3c6;
    --color-sangsangin-border-disabled: #3a4148;

    // Input
    --color-sangsangin-bg-input-default: #151e27;
    --color-sangsangin-bg-input-disabled: #232b35;
    --color-sangsangin-border-input-default: #1a222c;
    --color-sangsangin-border-input-disabled: #2a323b;

    // Background
    --color-sangsangin-bg-gray: #1c242d;
    --color-sangsangin-bg-card-ui: #18242f;
    --color-sangsangin-bg-divider: #232b35;
    --color-sangsangin-bg-disabled: #232b35;
    --color-sangsangin-bg-white: #1c2630;
    --color-sangsangin-bg-red: #2d1b1d;
    --color-sangsangin-bg-blue: #0f223d;
    --color-sangsangin-bg-mint: #0b3a40;
    --color-sangsangin-bg-dimmed: rgba(0, 0, 0, 0.8);
  }
`

export default colors
